import React from 'react'
import { update_paid_status } from '../app/apis';
import Navbar from './Navbar';
import {  toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom';

const ThankYou = () => {

    let navigate = useNavigate()

    return (
        <div className='w-full'>
            <Navbar/>
            <h1 className='font-semibold text-lg sm:text-xl py-2 text-center md:text-4xl heading text-purple-600 mb-2 mt-10'>
                Thank You
            </h1>

            <h3 className='font-semibold text-lg sm:text-xl py-2 text-center md:text-2xl heading text-purple-600 mb-2 mt-1'>
                For Filling The Form
            </h3>

            <div className='w-screen flex flex-col justify-center items-center mt-20'>
                <a href="https://matungaboarding.org/form.php" target="_blank" rel="noopener noreferrer">
                    <button className='px-3 py-2 rounded bg-purplegray-900 text-white'>Fill another form</button>
                </a>
            </div>

        </div>
    )
}

export default ThankYou