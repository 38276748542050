import React from 'react'

const ID = () => {

    return (
        //render
        <div class="flex">
        {/* <Preview id={'jsx-template'} > */}
            <div class="flex justify-center flex-col items-center w-full rounded-lg min-h-screen">
                <div class="w-[28em] h-auto rounded-lg bg-violet-200 pb-1">
                    <div class="w-full text-center m-0 bg-violet-800  rounded-t-lg text-violet-100 font-bold mb-2 px-1 py-2">
                        Shree Hirji Bhojraj & Sons Kuchi Visa Oswal Jain Chhatralaya (Matunga Boarding)
                    </div>
                <div class="flex w-full p-2">

                    <div class="w-1/3 mx-2">
                        <img src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png" alt="" class=" rounded rounded-lg w-full h-full"/>
                    </div>
                    <div class="w-2/3 mx-2 flex flex-col">
                        <p class="font-bold text-violet-700 pb-2">
                            Full Name : <span class="text-slate-900 font-semibold">First Middle Last</span>
                        </p>
                        <p class="font-bold text-violet-700 pb-2">
                            Village : <span class="text-slate-900 font-semibold">XYZ Vilage</span>
                        </p>
                        <p class="font-bold text-violet-700 pb-2">
                            Address : <span class="text-slate-900 font-semibold">Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati, fugit.</span>
                        </p>

                        <p class="text-violet-900 tracking-widest mt-2 font-bold text-xl">
                            102-946-839-204
                        </p>
                    </div>
                </div>
                </div>
            </div>
        {/* </Preview> */}
        </div>
    )
}

export default ID