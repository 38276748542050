import React, { useState } from 'react'
import { useEffect } from 'react'
import { getagentinfo, getTemporalAgentData } from '../../app/apis'

const filterFormField = [
    {
        "name": "From - date",
        "id": "From - date",
        "type": "date",
    },
    {
        "name": "To - date",
        "id": "To - date",
        "type": "date",
    },
]

let state = {}
    filterFormField.forEach(field => {
        state[field.id] = ''
})

const AgentInfo = () => {

    const [agentData, setAgentData] = useState([])
    const [temporalAgentData, setTemporalAgentData] = useState([])
    const [states, setStates] = useState({ ...state })
    const [value, setValue] = useState('initial');

    let getagentinfoapicall = async () => {
        let res = await getagentinfo()
        setAgentData(res.data)
    }

    let gettemporalagentinfoapicall = async () => {
        let res = await getTemporalAgentData(states)
        res = JSON.parse(res).data
        setTemporalAgentData(res)
    }

    useEffect(() => {
        getagentinfoapicall();
      }, [value])

    return (
        <>
            <div className=' w-full flex justify-between'>
                <h1 className='font-semibold text-lg sm:text-xl py-2 text-center md:text-4xl heading text-purple-600 mb-2'>
                    Agent Information (Total)
                </h1>
            </div>
            <div class="flex flex-col">
                <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                        <div class="overflow-hidden">
                            <table class="min-w-full">
                                <thead class="border-b">
                                    <tr className='bg-purple-800 uppercase'>
                                        <th scope="col" class="text-sm font-medium  text-gray-100 px-6 py-4 text-left">
                                            #
                                        </th>
                                        <th scope="col" class="text-sm font-medium text-gray-100 px-6 py-4 text-left">
                                            Agent Name
                                        </th>
                                        <th scope="col" class="text-sm font-medium text-gray-100 px-6 py-4 text-left">
                                            Email
                                        </th>
                                        <th scope="col" class="text-sm font-medium text-gray-100 px-6 py-4 text-left">
                                            New count
                                        </th>
                                        <th scope="col" class="text-sm font-medium text-gray-100 px-6 py-4 text-left">
                                            Update count
                                        </th>
                                        <th scope="col" class="text-sm font-medium text-gray-100 px-6 py-4 text-left">
                                            Reject count
                                        </th>
                                        <th scope="col" class="text-sm font-medium text-gray-100 px-6 py-4 text-left">
                                            Verify count
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {agentData.map((data, key) => (
                                        <tr key={key} class="border-b">
                                            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                {data.id}
                                            </td>
                                            <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                {data.name}
                                            </td>
                                            <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                {data.email}
                                            </td>
                                            <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                {data.new_count}
                                            </td>
                                            <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                {data.update_count}
                                            </td>
                                            <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                {data.reject_count}
                                            </td>
                                            <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                {data.verify_count}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            
            <br></br>
            <br></br>
            <br></br>

            <div className=' w-full flex justify-between'>
                <h1 className='font-semibold text-lg sm:text-xl py-2 text-center md:text-4xl heading text-purple-600 mb-2'>
                    Agent Information (Date wise)
                </h1>
            </div>
            <div className='flex flex-wrap justify-center '>
                {filterFormField.map((data, key) => (
                    <div className='flex flex-col p-2 border border-gray-600 rounded  m-2 w-1/3 '>
                        <h1 className='text-purplegray-900 mb-2'>{data.name}</h1>
                        <input
                            className='bg-purple-200 text-gray-700 appearance-none py-2 px-3 border rounded focus:bg-purple-50 focus:border-gray-400 focus:shadow-outline focus:outline-none'
                            type={data.type}
                            value={states[data.id]}
                            onChange={e => setStates({ ...states, [data.id]: e.target.value })}
                        />
                    </div>
                ))}
            </div>
            <div className="w-full my-3 flex justify-center">
                <button onClick={() => {gettemporalagentinfoapicall()}} className='bg-purple-600 px-7 py-2 text-white rounded-lg w-1/3'>Search</button>
            </div>

            <br></br>

            <div class="flex flex-col">
                <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                        <div class="overflow-hidden">
                            <table class="min-w-full">
                                <thead class="border-b">
                                    <tr className='bg-purple-800 uppercase'>
                                        <th scope="col" class="text-sm font-medium  text-gray-100 px-6 py-4 text-left">
                                            #
                                        </th>
                                        <th scope="col" class="text-sm font-medium text-gray-100 px-6 py-4 text-left">
                                            Agent Name
                                        </th>
                                        <th scope="col" class="text-sm font-medium text-gray-100 px-6 py-4 text-left">
                                            Email
                                        </th>
                                        <th scope="col" class="text-sm font-medium text-gray-100 px-6 py-4 text-left">
                                            New count
                                        </th>
                                        <th scope="col" class="text-sm font-medium text-gray-100 px-6 py-4 text-left">
                                            Update count
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {temporalAgentData.map((data, key) => (
                                        <tr key={key} class="border-b">
                                            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                {data.id}
                                            </td>
                                            <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                {data.name}
                                            </td>
                                            <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                {data.email}
                                            </td>
                                            <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                {data.new}
                                            </td>
                                            <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                {data.old}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AgentInfo