let myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
// myHeaders.append('Access-Control-Allow-Origin', "*")

const baseURL = 'https://matungaboarding.pythonanywhere.com/'
// const baseURL = 'https://8b01-2603-6081-24f0-9ca0-e997-e082-e07d-8c93.ngrok-free.app/'

//Login API
//Post Request


//cancel membership
export const cancelMembership = async (data) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(data);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    const response = await fetch(baseURL + "form/cancel", requestOptions)
    return response.text()
}


export const handleLogin = (e, email, password) => {
    e.preventDefault();
    let raw = JSON.stringify({
        "email": email,
        "password": password
    });
    let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };
    fetch(baseURL + "auth/token/", requestOptions)
        .then(response => response.json())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));
}

//GET all applicants
export const getAllApplicants = async (page) => {

    let requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };

    const response = await fetch(baseURL + `form/?page=${page}`,requestOptions)
    const data = await response.json()
    return {
        data : data,
        status : response.status
    };
}

//GET new applications
export const getNewApplicantions = async (page) => {

    let requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };
    const response = await fetch(baseURL + `form/new?page=${page}`,requestOptions)
    const data = await response.json()
    return data;
}

//GET updated applications
export const getUpdatedApplicantions = async (page) => {

    let requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };
    const response = await fetch(baseURL + `form/updated?page=${page}`,requestOptions)
    const data = await response.json()
    return data;
}

//GET declined applications
export const getDeclinedApplicantions = async (page) => {

    let requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };
    const response = await fetch(baseURL + `form/declined?page=${page}`,requestOptions)
    const data = await response.json()
    return data;
}

//GET paid applications
export const getPaidApplicantions = async (page) => {

    let requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };
    const response = await fetch(baseURL + `form/paid?page=${page}`,requestOptions)
    const data = await response.json()
    return data;
}

// GET all berified members
export const getAllVerifiedMembers = async (page) => {

    let requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };
    const response = await fetch(baseURL + `form/verifiedmembers?page=${page}`,requestOptions)
    const data = await response.json()
    return data;
}

//GET verified applications
export const getVerifiedApplications = async (page) => {

    let requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };
    const response = await fetch(baseURL + `form/verified?page=${page}`,requestOptions)
    const data = await response.json()
    return data;
}


//MARK AS DUPLICATE
export const deleteold = async (data) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(data);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    const response = await fetch(baseURL + "form/delete_old", requestOptions)
    return response.text()
}

//Remove from duplicate
export const remove_duplicate = async (data) => {

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(data);

    var requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    const response = await fetch(baseURL + "form/delete_old", requestOptions)
    return response.text()
}

//Allows autofill via fid
export const fid_search = async (data) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(data);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    const response = await fetch(baseURL + "form/fid_search", requestOptions)
    return response.text()
}

//GET filtered Admin applications
export const getFilteredAdminApplications = async (data) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(data);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    const response = await fetch(baseURL + "form/admindashboardfilter", requestOptions)
    return response.text()
}

//Create ID Cards
export const createIDCard = async (data) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(data);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    const response = await fetch(baseURL + "form/create_id_card", requestOptions)
    return response.text()
}

//Admin Filter to excel report
export const adminFilterReport = async (data) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(data);

    var requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    const response = await fetch(baseURL + "form/admindashboardfilter", requestOptions)
    return response
}

//temporal filter
export const getTemporalAgentData = async (data) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(data);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    const response = await fetch(baseURL + "form/temporal_filter", requestOptions)
    return response.text()
}

//GET filtered Agent applications
export const getFilteredAgentApplications = async (data) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(data);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    const response = await fetch(baseURL + "form/agentdashboardfilter", requestOptions)
    return response.text()
}

//GET agentbucket list
export const getAgentBucket = async (page) => {

    let requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };
    const response = await fetch(baseURL + `form/agentbucket?page=${page}`,requestOptions)
    const data = await response.json()
    return data;
}

export const getDuplicates = async (page) => {

    let requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };
    const response = await fetch(baseURL + `form/delete_old?page=${page}`,requestOptions)
    const data = await response.json()
    return data;
}

//GET rejected Applications
export const getRejectedApplications = async (page) => {
    let requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };
    const response = await fetch(baseURL + `form/rejected?page=${page}`,requestOptions)
    const data = await response.json()
    return data;
}

//admin verify POST 

export const adminVerification = async (data) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + JSON.parse(localStorage.getItem("data"))["token"]);

    var raw = JSON.stringify(data);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    const response = await fetch(baseURL + "form/verify", requestOptions)
    return response.json()
}

//SEND PAYMENT LINK API CALL
export const sendPaymentLink = async (data) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(data);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    const response = await fetch(baseURL + "form/payment", requestOptions)
    return response.json()
}


//agent verify PUT 

export const agentVerification = async (data, access_token) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + access_token);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(data);
    var requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    const response = await fetch(baseURL + "form/agentverify", requestOptions)
    return response.json()
}

export const getagentinfo = async () => {
    let requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };
    const response = await fetch(baseURL + `form/getagentinfo`,requestOptions)
    const data = await response.json()
    return data;
}

export const getreportinfo = async () => {
    let requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };
    const response = await fetch(baseURL + `form/reports`,requestOptions)
    const data = await response.json()
    return data;
}

export const create_report = async (data) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(data);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    const response = await fetch(baseURL + "form/reports", requestOptions)
    return response
}

export const update_paid_status = async (data) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(data);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    const response = await fetch(baseURL + "form/update_paid_status", requestOptions)
    return response.json()
}

export const send_update_link_api = async (data) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(data);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    const response = await fetch(baseURL + "form/update_link", requestOptions)
    return response.json()
}

export const get_individual_update_info = async (data) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json")

    var raw = JSON.stringify(data)
    var requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    const response = await fetch(baseURL + "form/update_link", requestOptions)
    return response.json()
}

