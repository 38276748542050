import React from 'react'
import { update_paid_status } from '../app/apis';
import Navbar from './Navbar';
import {  toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import LoadingBar from 'react-top-loading-bar'
import { VscLoading } from "react-icons/vsc";

const Payment = () => {

    let navigate = useNavigate()
    const [progress, setProgress] = useState(0)
    const [load, setLoad] = useState(false)
    const isInitialMount = useRef(true);

    useEffect(() => {
        if (isInitialMount.current) {
            update_paid_status_api_call()
            isInitialMount.current = false;
         } else {
             // Your useEffect code here to be run on update
         }
      },[]
    )

    let update_paid_status_api_call = async () => {
        setProgress(50)
        setLoad(true)
        // console.log("update paid stat api call")
        // console.log("Test")
        try{
            let url = window.location.href
            url = new URL(url)
            console.log(url)
            let token = url.searchParams.get("razorpay_payment_link_id");
            console.log(token)
            if(token===null){
                toast.error('Unauthorized payment attempt', {
                    position: "top-center",
                    autoClose: 4000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
            }else{
                let res = await update_paid_status({"token": token})
                console.log(res)
                toast.success('Payment Successful', {
                    position: "top-center",
                    autoClose: 4000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
            }
        }catch(e){
            console.log(e)
            toast.error('Something went Wrong :(', {
                position: "top-center",
                autoClose: 4000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
        }
        setProgress(100)
        setLoad(false)
    }

    const pay = () => {
        let options = {
            "key": "rzp_test_i0MRWIGptKRVZA",
            "amount": "61500",
            "currency": "INR",
            "description": "Shree Hirji Bhojraj & Sons Kuchi Visa Oswal Jain Chhatralaya (Matunga Boarding)",
            "prefill": {
                "email": "amaygada@gmail.com",
                "contact": +918369357433,
            },
            "method": {
                "upi": true,
                "netbanking": false,
                "card": false,
                "wallet": false,
                "nb": false,
            },
            "handler": async (response) => {
                console.log(response)
                await update_paid_status_api_call(response["razorpay_payment_id"])
            },
        };
        var rzp = new window.Razorpay(options);
        rzp.open();
    }

    let payData = [
        {
            "d": "Membership Fee",
            "a": "Rs. 500"
        },
        {
            "d": "GST 18%",
            "a": "Rs. 90"
        },
        {
            "d": "Total",
            "a": "Rs. 590"
        }
    ]

    return (
        <div className='w-full'>
            <Navbar/>
            <LoadingBar color='#f11946' progress={progress} />
            <h1 className='font-semibold text-lg sm:text-xl py-2 text-center md:text-4xl heading text-purple-600 mb-2 mt-10'>
                Payment Breakdown
            </h1>
            <table class="w-full mt-10 justify-center items-center flex flex-col">
                
                <tbody>
                    {payData.map((data, key) => (
                        <tr key={key} class="border-b">
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                {data.d}
                            </td>
                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                {data.a}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {load ? (
                <div className='flex flex-col'>
                    <h1 className='font-semibold text-lg sm:text-xl py-2 text-center md:text-2xl heading text-purple-600 mb-2 mt-20'>
                        Processing your payment ...
                    </h1>
                    <h1 className='font-semibold text-lg sm:text-xl py-2 text-center md:text-4xl heading text-purple-600 mb-2 mt-5'>
                        Please wait for a moment
                    </h1>
                </div>
            ): (
                <div>
                    <h1 className='font-semibold text-lg sm:text-xl py-2 text-center md:text-4xl heading text-purple-600 mb-2 mt-20'>
                        Payment Received!
                    </h1>
                    <h1 className='font-semibold text-lg sm:text-xl py-2 text-center md:text-4xl heading text-purple-600 mb-2 mt-10'>
                        Welcome to Matunga Boarding!
                    </h1>
                </div>
            )}
        </div>
    )
}

export default Payment