import { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { MdAddCircleOutline } from "react-icons/md";
import ReactImageUploading from "react-images-uploading";
import CaptureImage from "./../../features/form/inputElements/CaptureImage";
import { ref, uploadBytes , getDownloadURL } from 'firebase/storage'
import { useSelector} from 'react-redux';
import {  toast } from 'react-toastify';

const videoConstraints = {
  width: 220,
  height: 200,
  facingMode: "user"
};

const PhotoAdmin = ({ question, appid, folder, setFolder, setUrl, name="Payment Receipt"}) => {
  let object = folder
  const [image, setImage] = useState(folder[name]);
  const [capturedImage, setCapturedImage] = useState('')
  const [type, setType] = useState('upload')

  const onChange = async (imageList, addUpdateIndex) => {
    setImage(imageList[0]);
    object[name] = imageList[0]
    setFolder(object)

    await uploadImage(object[name], name)
  };
  useEffect(() => {
    object[name] = capturedImage
    setFolder(Object)
  }, [capturedImage])

  const { storage } = useSelector(state => state.firebase);

  const uploadImage = async (img, name) => {
    try{
        toast.success('Uploading... Wait for a few seconds', {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        console.log("uploading")
        // console.log(img)
        if (img === null) return;
        const imageRef = ref(storage, `images/${name+"_"+appid+".jpg"}`)
        // console.log(img)
        let snapshot = await uploadBytes(imageRef, img.file)
        let url = await getDownloadURL(snapshot.ref)
        // console.log(url)
        setUrl(url)
        toast.success('Upload Successful', {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }catch(e){
        toast.success('Upload Failed', {
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
  }
   
  return (
    <div className='flex flex-col pt-2'>
      <div className="flex flex-col justify-center items-center">
        <h1 className='text-purplegray-400 mb-2'>{question}</h1>
        <div className={`flex gap-4 mb-2`}>
          <label class={`flex items-center gap-1 text-sm text-purplezinc`}>
            <input type="radio" checked={type==='upload'} onChange={async e => {
                const object = {...folder, [name]: null}
                setFolder(object)
                setType(e.target.value)
              }} value='upload' class="w-4 h-4 accent-purplegray-600 bg-gray-100 border-gray-300" />
            Upload Photo
          </label>
          <label class={`flex items-center gap-1 text-sm text-purplezinc`}>
            <input type="radio" checked={type==='click'} onChange={ async e => {
                const object = {...folder, [name]: null}
                setFolder(object)
                setType(e.target.value)
              }} value='click' class="w-4 h-4 accent-purplegray-600 bg-gray-100 border-gray-300" />
            Click Photo
          </label>
        </div>
        {
          type === 'upload' ?
          <ReactImageUploading
            value={image}
            onChange={onChange}
            dataURLKey="data_url"
          >
            {({
              onImageUpload,
              onImageRemove,
            }) => (
              <div className="upload__image-wrapper">
                {image ? (
                  <div className="h-40 w-28 bg-white relative">
                    <div className="w-full h-full flex justify-center items-center" onClick={onImageUpload}>
                      <img src={image['data_url']} alt="" 
                        className="w-auto h-auto" 
                        onClick={() => {
                          setImage(null)
                          onImageRemove(0)
                        }} 
                      />
                    </div>
                    <div className="absolute top-[-10px] right-[-10px] p-1 rounded-full bg-purple-200">
                      <AiOutlineClose
                        className="text-red-500 text-sm"
                        onClick={() => {
                          setImage(null)
                          onImageRemove(0)
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="h-40 w-28 bg-white flex justify-center items-center" onClick={onImageUpload}>
                    <MdAddCircleOutline className="w-12 h-12 text-gray-600" />
                  </div>
                )}

              </div>
            )}
          </ReactImageUploading> :
          <CaptureImage capturedImage={capturedImage} setCapturedImage={setCapturedImage} name={name} uploadImage={uploadImage}/>
        }
        {/* <button className='w-full bg-purplegray-600 rounded p-1 m-1 text-white' onClick={() => uploadImage(image)}>Upload Image</button> */}
      </div>
    </div>
  )
}

export default PhotoAdmin