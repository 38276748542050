import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import Navbar from '../../components/Navbar'
import { BsPersonFill, BsPeopleFill, BsExclamationCircleFill } from "react-icons/bs";
import { IoLocationSharp, IoMenuSharp, IoCloseSharp } from "react-icons/io5";
import { MdVerifiedUser, MdSystemUpdateAlt, MdDeleteForever } from "react-icons/md";
import { TextField } from './inputElements/TextField';
import Radio from './inputElements/Radio';
import File from './inputElements/File';
import Photo from './inputElements/Photo';
import { Calender } from './inputElements/Calender';
import { addFamilyMember, removeFamilyMember, removeReference, setField, clearFields, resetFamilyMembers, resetForm } from './formSlice';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { usePostNewFormMutation, useProtectedMemberAdditionMutation, useUpdateExistingFormMutation } from './formApiSlice';
import { usePostExistingFormMutation } from './formApiSlice';
import { setError } from './fieldsSlice';
import Verify from './inputElements/Verify';
import { DropDown } from './inputElements/DropDown';
import { ref, uploadBytes , getDownloadURL } from 'firebase/storage'
import { VscLoading } from 'react-icons/vsc';
import { fid_search } from '../../app/apis';


const FileOrPhotoSelctor = ({ question, name, required, errorMsg, error, folder, setFolder, aadhar, setAadhar }) => {
  return (
    <div className="">
      <h1 className='text-purplegray-400 mb-2'>Choose way to upload Aadhar card or PAN card</h1>
      <div className={`flex gap-4`}>
        <label class={`flex items-center gap-1 text-sm text-purplezinc`}>
          <input type="radio" checked={aadhar === 'file'} onChange={e => {
              const object = {...folder, [name]: null}
              setFolder(object)
              setAadhar(e.target.value)
            }} value='file' class="w-4 h-4 accent-purplegray-600 bg-gray-100 border-gray-300" />
          File
        </label>
        <label class={`flex items-center gap-1 text-sm text-purplezinc`}>
          <input type="radio" checked={aadhar === 'photo'} onChange={e => {
              const object = {...folder, [name]: null}
              setFolder(object)
              setAadhar(e.target.value)
            }} value='photo' class="w-4 h-4 accent-purplegray-600 bg-gray-100 border-gray-300" />
          Photo
        </label>
      </div>
      {aadhar === 'file' && <File folder={folder} setFolder={setFolder} errorMsg={errorMsg} required={required} question={question + ' PDF'} name={name} error={error} />}
      {aadhar === 'photo' && <Photo folder={folder} setFolder={setFolder} errorMsg={errorMsg} required={required} question={question + " Photo"} name={name} error={error} />}
    </div>
  )
}

const IconSelector = ({checked}) => {
  return (
    <>
      {checked === 'Personal Details' && <BsPersonFill className='w-full h-full text-purplegray-300' />}
      {checked === 'Family Details' && <BsPeopleFill className='w-full h-full text-purplegray-300' />}
      {checked === 'Address Details' && <IoLocationSharp className='w-full h-full text-purplegray-300' />}
      {checked === 'Other' && <BsExclamationCircleFill className='w-full h-full text-purplegray-300' />}
      {checked === 'Verification' && <MdVerifiedUser className='w-full h-full text-purplegray-300' />}
    </>
  )
}

const Tabs = ({ checked, setChecked, tabs }) => {
  const sectionList = Object.keys(tabs)
  return (
    <div className='hidden sm:grid grid-flow-col grid-cols-auto w-full my-4'>
      {sectionList.map(section => (
        <label class={`flex flex-col items-center gap-1 text-sm text-purplezinc`} onClick={() => setChecked(section)}>
          {checked === section ? (
            <>
              <input type="radio" value="" name="colored-radio" className="w-4 h-4 hidden accent-purplegray-900 bg-gray-100 border-gray-300" />
              <div className="flex w-12 h-12 bg-purplegray-900 p-3 rounded-full">
                <IconSelector checked={section} />
              </div>
              <h1 className='text-lg text-purplezinc'>
                {section}
              </h1>
            </>
          ) : (
            <>
              <input type="radio" value="" name="colored-radio" className="w-4 h-4 hidden accent-purplegray-900 bg-gray-100 border-gray-300" />
              <div className="flex w-12 h-12 bg-purplegray-100 p-3 rounded-full">
                <IconSelector checked={section} />
              </div>
              <h1 className='text-lg text-purplegray-400'>
                {section}
              </h1>
            </>
          )}
        </label>
      ))}
    </div>
  )
}

const FieldSelector = ({ data, index, folder, setFolder, aadhar, setAadhar }) => {
  const { type } = useParams()
  return (
    <>
      {data.type === 'text' && <TextField errorMsg={data.errorMsg} required={data.required} regex={data.regex} question={data.question} name={data.name} type={data.type} error={data.error} index={index} />}
      {data.type === 'verify' && <Verify url_type={type} errorMsg={data.errorMsg} required={data.required} regex={data.regex} question={data.question} name={data.name} type={data.type} error={data.error} index={index} />}
      {data.type === 'radio' && <Radio errorMsg={data.errorMsg} required={data.required} question={data.question} row={true} options={data.options} name={data.name} error={data.error} index={index} />}
      {data.type === 'dropdown' && <DropDown errorMsg={data.errorMsg} required={data.required} question={data.question} row={true} options={data.options} name={data.name} error={data.error} index={index} />}
      {data.type === 'file' && <File folder={folder} setFolder={setFolder} errorMsg={data.errorMsg} required={data.required} question={data.question} name={data.name} error={data.error} />}
      {data.type === 'photo' && <Photo folder={folder} setFolder={setFolder} errorMsg={data.errorMsg} required={data.required} question={data.question} name={data.name} error={data.error} />}
      {data.type === 'file/photo' && <FileOrPhotoSelctor aadhar={aadhar} setAadhar={setAadhar} folder={folder} setFolder={setFolder} errorMsg={data.errorMsg} required={data.required} question={data.question} name={data.name} error={data.error} />}
      {data.type === 'date' && <Calender errorMsg={data.errorMsg} required={data.required} question={data.question} name={data.name} type={data.type} error={data.error} index={index} />}
    </>
  )
}

const Member = ({ index,  member, sections }) => {
  const dispatch = useDispatch()
  const fields = useSelector(state => state.fields)
  const update = (index) => {
    sections.forEach(section => {
      dispatch(setField([fields[section].name, member[fields[section].name]]))
    })
    dispatch(removeFamilyMember(index))
  }
  return (
    <div className='flex justify-between items-center bg-purplegray-100 p-4 rounded-xl my-2'>
      <h1>Name: {member.FamilyMemberName}</h1>
      <h1>Relation: {member.Relation}</h1>
      <h1>Date of Birth: {member.RelativeDateOfBirth}</h1>
      <div>
        <button className='bg-green-400 px-1 py-1 text-xl rounded mr-2' onClick={() => update(index)}><MdSystemUpdateAlt /></button>
        <button className='bg-red-400 px-1 py-1 text-xl rounded' onClick={() => {dispatch(removeFamilyMember(index))}}><MdDeleteForever /></button>
      </div>
    </div>
  )
}

const FamilyForm = ({ sections, data }) => {
  const dispatch = useDispatch()
  const fields = useSelector(state => state.fields)
  const form = useSelector(state => state.form)
  
  let get_fid = () => {
    return form["FamilyID"]
  } 
  let autofil = async () => {
    try{
      let fid = get_fid()
      if(fid===null || fid==="" || fid===undefined){alert("Invalid FID"); return;}
      let response = await fid_search({"FID": fid})
      response = JSON.parse(response)

      if(response["val"]==="Invalid") alert("Invalid FID")
      else{
        for(let i in response["data"]){
          dispatch(setField([i,response["data"][i]]))
        }
      }
    }catch(e){
      console.log(e)
    }
  } 

  return (
    <div>
      <FieldSelector data={fields[33]} index={33} />
      <div className="flex justify-around">
        <button className="bg-purplegray-600 p-3 my-10 text-purple-50 rounded" onClick={async () => {await autofil()}}>AUTOFILL USING FAMILY ID</button>
      </div>
    </div>
  )
}

const AuthorizedSection = () => {
  const dispatch = useDispatch()
  const form = useSelector(state => state.form)
  // console.log(form)
  const {isAdmin} = useSelector(state => state.auth)

  return (
    <div>
      <div className='pt-2'>
        <h1 className='text-purplegray-400 mb-2'></h1>
        <div className={`flex gap-3`}>
          {["Deceased", "Other", "None"].map(option => (
            <label class={`flex items-center gap-1 text-sm text-purplezinc`}>
              <input type="radio" checked={form.MDO["value"] === option } onChange={e => dispatch(setField(["MDO", {"value":e.target.value, "other": ""}]))} value={option} class="w-4 h-4 accent-purplegray-600 bg-gray-100 border-gray-300" />
              {option}
            </label>
          ))}
        </div>
      </div>

      {form.MDO["value"] === 'Other' &&  
        <div className='flex flex-col pt-2'>
          <h1 className='text-purplegray-400 mb-2'>Other</h1>
          <input value={form.MDO.other} onChange={e=>{dispatch(setField(["MDO", {"value": form.MDO.value, "other": e.target.value}]))}} className='text-gray-700 appearance-none py-2 px-3 border rounded bg-blue-100 focus:border-gray-400 focus:shadow-outline focus:outline-none' />
        </div>
      }
      {isAdmin && 
        <div className="">
          <div className='flex flex-col pt-2 w-fit'>
            <label className="flex items-center gap-1 text-sm text-purplezinc">
              <input checked={form.Verified} onChange={() => dispatch(setField(['Verified', !form.Verified]))} type="checkbox" className="accent-purplegray-900 h-6" />
              Verified
            </label>
          </div>
          <div className='flex flex-col pt-2 w-fit'>
            <label className="flex items-center gap-1 text-sm text-purplezinc">
              <input checked={form.Paid} onChange={() => dispatch(setField(['Paid', !form.Paid]))} type="checkbox" className="accent-purplegray-900 h-6" />
              Paid
            </label>
          </div>
          <div className='flex flex-col pt-2 w-fit'>
            <label className="flex items-center gap-1 text-sm text-purplezinc">
              <input checked={form.Processed} onChange={() => dispatch(setField(['Processed', !form.Processed]))} type="checkbox" className="accent-purplegray-900 h-6" />
              Processed
            </label>
          </div>

          <div className='flex flex-col pt-2 w-fit'>
            <label className="flex items-center gap-1 text-sm text-purplezinc">
              <input checked={form.Rejected} onChange={() => dispatch(setField(['Rejected', !form.Rejected]))} type="checkbox" className="accent-purplegray-900 h-6" />
              Rejected
            </label>
          </div>

        </div>
      }
    </div>
  )
}

const OtherForm =({ sections }) => {
  const { type } = useParams()
  const dispatch = useDispatch()
  const fields = useSelector(state => state.fields)
  const form = useSelector(state => state.form)
  const { token } = useSelector(state => state.auth)
  const references = form[fields[sections[2]].name]
  const [errors, setErrors] = useState(false)
  const [reference, setReference] = useState({
    name: '',
    number: '',
    designation: '',
  })

  const addReference = () => {
    if(reference.name !== '' && reference.number !== '' && reference.designation !== '') {
      let newRef = Array.isArray(references) ? [...references, reference] : [references, reference]
      // console.log(newRef)
      dispatch(setField([fields[sections[2]].name, newRef]))
      setReference({
        name: '',
        number: '',
        designation: '',
      })
      setErrors(false)
    } else setErrors(true)
  }
  useEffect(() => {
    // console.log(sections[2])
    if(references.length === fields[sections[2]].max) {
      dispatch(setError({name: sections[2], error: false}))
    }
  }, [references])

  return (
    <div>
      <FieldSelector data={fields[sections[0]]} index={sections[0]} />
      {form[fields[sections[0]].name] === 'Yes' && <FieldSelector data={fields[sections[1]]} index={sections[1]} />}
      
      {
        (form["New"]===true || type==='new') ? (
          <div className={type === 'existing' && 'hidden'}>
            <h1 className='text-purplezinc text-3xl my-2'>KVO References <span className='text-xl'>(compulsory 2)</span></h1>
            {(fields[sections[2]].error) ? <p className='text-red-500 text-sm mt-1'>{fields[sections[2]].errorMsg}</p> : '‎'}
            {references.length > 0 && references.map((reference, index) => (
              <div className='flex justify-between items-center bg-purplegray-100 p-4 rounded-xl my-2'>
                <h1>Name: {reference.name}</h1>
                <h1>Number: {reference.number}</h1>
                <h1>Village: {reference.designation}</h1>
                <button className='bg-red-400 px-1 py-1 text-xl rounded' onClick={() => {dispatch(removeReference(index))}}><MdDeleteForever /></button>
              </div>
            ))}
            {((references.length < fields[sections[2]].max || references) && type !== 'existing') && (
              <div className="grid grid-cols-4 gap-6 my-5">
                <div className='flex flex-col pt-2'>
                  <h1 className='text-purplegray-400 mb-2'>Enter Name</h1>
                  <input value={reference.name} onChange={e => setReference(prevState => ({...prevState, name: e.target.value}))} type='text' className='text-gray-700 appearance-none py-2 px-3 border rounded bg-blue-100 focus:border-gray-400 focus:shadow-outline focus:outline-none' />
                </div>
                <div className='flex flex-col pt-2'>
                  <h1 className='text-purplegray-400 mb-2'>Enter Number</h1>
                  <input value={reference.number} onChange={e => setReference(prevState => ({...prevState, number: e.target.value}))} type='text' className='text-gray-700 appearance-none py-2 px-3 border rounded bg-blue-100 focus:border-gray-400 focus:shadow-outline focus:outline-none' />
                </div>
                <div className='flex flex-col pt-2'>
                  <h1 className='text-purplegray-400 mb-2'>Enter Village</h1>
                  <input value={reference.designation} onChange={e => setReference(prevState => ({...prevState, designation: e.target.value}))} type='text' className='text-gray-700 appearance-none py-2 px-3 border rounded bg-blue-100 focus:border-gray-400 focus:shadow-outline focus:outline-none' />
                </div>
                <button onClick={() => addReference()}>Add</button>
              </div>
            )}
            {errors && <h1 className="text-xl text-center my-2 text-red-400">Please fill all fields</h1>}
          </div>
        ):(
          <div></div>
        )
      }

      {token && type!=='new' && type!=='existing' && <AuthorizedSection />}
    </div>
  )
}

const SideBar = ({ checked, setChecked, tabs }) => {
  const sectionList = Object.keys(tabs)
  const [open, setOpen] = React.useState(false);
  return (
    <div className='sm:hidden flex'>
      <IoMenuSharp className='text-2xl my-4' onClick={() => setOpen(true)} />
      <h5 className="mt-3.5 mx-5">&lt;--  click to access all sections</h5>
      {open && (
        <div className="bg-purplegray-500 z-10 absolute flex flex-col top-0 left-0 h-full min-h-screen items-end">
          <IoCloseSharp className='mt-4 mr-4 text-2xl' onClick={() => setOpen(false)} />
          <div className='flex flex-col py-6 px-4 gap-6'>
            {sectionList.map(section => (
              <label class={`flex items-center gap-1 text-sm text-white`} onClick={() => setChecked(section)}>
                {checked === section ? (
                  <>
                    <input type="radio" value="" name="colored-radio" className="w-4 h-4 hidden accent-purple-600 bg-gray-100 border-gray-300" />
                    <div className="flex w-8 h-8 bg-purplegray-900 p-2 rounded-full">
                      <IconSelector checked={section} />
                    </div>
                    <h1 className='text-white'>
                      {section}
                    </h1>
                  </>
                ) : (
                  <>
                    <input type="radio" value="" name="colored-radio" className="w-4 h-4 hidden accent-purple-600 bg-gray-100 border-gray-300" />
                    <div className="flex w-8 h-8 bg-purplegray-100 p-2 rounded-full">
                      <IconSelector checked={section} />
                    </div>
                    <h1 className='text-white'>
                      {section}
                    </h1>
                  </>
                )}
              </label>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

const FormVariant = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { type } = useParams()
//   const [postNewForm, {isLoading: loadingNew}] = usePostNewFormMutation()
  const [postExistingForm, {isLoading: loadingExisting}] = usePostExistingFormMutation()
  const [updateExistingForm, {isLoading: loadingUpdate}] = useUpdateExistingFormMutation()
  const [protectedNewForm, {isLoading: loadingNew}] = useProtectedMemberAdditionMutation()
  const sections = useSelector(state => state.section)
  const fields = useSelector(state => state.fields)
  const form = useSelector(state => state.form)
  const {token} = useSelector(state => state.auth)
  const [checked, setChecked] = useState(Object.keys(sections)[0])
  const [folder, setFolder] = useState({
    'AadharS3': null,
    'PhotoS3': null
  })
  const [aadhar, setAadhar] = useState('file')
  const { storage } = useSelector(state => state.firebase);
  const location = useLocation();
  const {isAdmin} = useSelector(state => state.auth)
  // let data = null

  useEffect(() => {
    dispatch(setField(["mobile_otp error", false]))
    dispatch(setField(["email_otp error",false]))
    dispatch(setField(["mobile_otp", null]))
    dispatch(setField(["email_otp", null]))
    dispatch(setField(["verified_mobile", false]))
    dispatch(setField(["verified_email", false]))
    dispatch(setField(["PhotoS3", null]))
    dispatch(setField(["AadharS3", null]))
    dispatch(setField(["InvoiceLink", ""]))
    dispatch(setField(["IDCardLink", ""]))
    dispatch(setField(["MemberID", ""]))
    dispatch(setField(["ApplicationID", ""]))
    dispatch(setField(["FormFilledBy", {}]))
    dispatch(setField(["RazorpayPaymentID", ""]))
    dispatch(setField(["created_at", ""]))

    if(type!=='new' && type!=='existing') {
      if(!token) navigate('/')
      const { userinfo } = location.state;
      // data = userinfo
      const { bucketinfo } = location.state;
      // console.log(userinfo, bucketinfo)

      if(userinfo){
        Object.entries(userinfo).forEach(([key, value]) => {
          if(form[key]===null) {
            if(key==='FamilyDetails') {
              dispatch(resetFamilyMembers())
              if(!Array.isArray(value)) {
                dispatch(addFamilyMember(value.members))
              } else if(value!==[]) dispatch(addFamilyMember(value))
            } else {
              dispatch(setField([key, value]))
            }
          } else if(form[key]) {
            if(key==='FamilyDetails') {
              dispatch(resetFamilyMembers())
              if(!Array.isArray(value)) {
                if(value.members) dispatch(addFamilyMember(value.members))
              } else if(value!==[]) dispatch(addFamilyMember(value))
            } else {
              dispatch(setField([key, value]))
            }
          } else dispatch(setField([key, value]))
        })
      } else {
        Object.entries(bucketinfo).forEach(([key, value]) => {
          if(form[key]===null) {
            if(key==='FamilyDetails') {
              dispatch(resetFamilyMembers())
              if(!Array.isArray(value)) {
                dispatch(addFamilyMember(value.members))
              } else if(value!==[])  dispatch(addFamilyMember(value))
            } else dispatch(setField([key, value]))
          }
          if(form[key]) {
            if(key==='FamilyDetails') {
              // console.log(value)
              dispatch(resetFamilyMembers())
              if(!Array.isArray(value)) {
                // console.log('hi')
                if(value.members) dispatch(addFamilyMember(value.members))
              } else if(value!==[]) dispatch(addFamilyMember(value))
            } else dispatch(setField([key, value]))
          }
        })
      }
    }
    // console.log(form)
    return () => dispatch(resetForm())
  }, [])
  
  const uploadImage = (img, name) => {
    if (img === null) return;
    const imageRef = ref(storage, `images/${name+"_"+form["FirstName"]+"_"+form["MiddleName"]+"_"+form["LastName"]+"_"+form["AadharNumber"]+".jpg"}`)
    return uploadBytes(imageRef, img.file).then((snapshot) => {
      return getDownloadURL(snapshot.ref).then((url) => {
        // console.log(url)
        dispatch(setField([name, url]))
      })
    })
  }

  let check_dob = () => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1
    let month = parseInt(form["DateOfBirth"].substring(3,5))
    let year = parseInt(form["DateOfBirth"].substring(6))

    if(year<500) year=year+2000

    if(currentYear - year >= 18){
      if(currentYear - year == 18){
        if(currentMonth - month >= 0) return true
        else return false
      }
    }else return false
  }

  const submit = async () => {
    console.log("FV")
    let empty = []
    let count = 0
    if(form.MDO.value==="None" || form.MDO.value===undefined || form.MDO.value===null){
      Object.entries(form).forEach((value, index) => {
        if(index < Object.keys(fields).length-4) {
          const regex = fields[index+1]?.regex
          if(fields[index+1].required) {
            if (value[1] === null || value[1] === '' || value[1] === [] || value[1] === {}) {
              empty.push(Object.values(fields)[index].label)
              dispatch(setError({name: index+1, error: true}))
              count++
            } 
            else if (regex && !regex?.test(value[1])) {
              empty.push(Object.values(fields)[index].label)
              dispatch(setError({name: index+1, error:true}))
              count++
            }
            else if (regex==='' && value[1]==='') {
              empty.push(Object.values(fields)[index].label)
              dispatch(setError({name: index+1, error:true}))
              count++
            } else dispatch(setError({name: index+1, error:false}))
          }
        }
        // if(value[0] === 'FamilyDetails' && value[1]?.number === 0) {
        //   empty.push("Family Details")
        //   count++
        // }
        if(value[0] === 'References' && value[1].length !== Object.values(fields)[index].max && type!=='existing') {
          if((form["New"]===true || type==='new')) {
            empty.push('Add ' + Object.values(fields)[index].max + ' references')
            dispatch(setError({name: index+1, error: true}))
            count++
          }
        }
      })

      if(form["FamilyID"]!==null && form["FamilyID"]!==undefined && form["FamilyID"]!==""){
        if(form["FamilyID"].length>4){
          if(form["FamilyID"].substring(0,4)!=="FID_"){
            count++
            empty.push('Family ID Invalid')
          }
        }else{
          count++
          empty.push('Family ID Invalid')
        }
      }

      if(form["DateOfBirth"] !== null){
        console.log("hi")
        if(check_dob() === false){
          empty.push('Date Of Birth')
          dispatch(setError({name: 6, error: true}))
        }
      }

      if(true){//type==="new" || type==="existing"){
        if(form["Email"]==="" || form["Email"]===null || form["Email"]===undefined){
          //
        }else{
          // console.log(form["email_otp error"])
          // if(form["email_otp"]==="" || form["email_otp"]===undefined || form["email_otp"]===null || form["email_otp error"]){
          //   empty.push("Email OTP"); 
          //   count++;
          // }
        }

        //UNCOMMENT AFTER WHATSAPP INTEGRATION
        if(form["mobile_otp"]==="" || form["mobile_otp"]===undefined || form["mobile_otp"]===null){
          empty.push("Mobile OTP"); 
          count++;
        }
      }

      if(form["TnC"]!=="I agree with the declaration"){
        empty.push("Accept Terms and Conditions")
        count++;
      }

      if(form["GovernmentID"]===null || form["GovernmentID"]=="None"){
        empty.push("Please select the type of Government ID you submitted.")
        count++;
      }

      if(form["Gender"]==="Female" && form["MaritalStatus"]==="Married"){
        if(form["MaidenName"]===null || form["MaidenName"].trim()===""){
          empty.push("Maiden Name is mandatory")
          count++
        }
        if(form["MaidenVillage"]===null || form["MaidenVillage"].trim()===""){
          empty.push("Maiden Village is mandatory")
          count++
        }
      } 
    } 

    if(isAdmin) count=0

    if (count !== 0) {

      alert(`Please fill out the following fields: ${empty.join(', ')}`)
    }else if(form["TnC"]!=="I agree with the declaration"){
      alert("Accept the declaration to proceed")
    }else {
      let newData = {
        ...form,
        "FamilyDetails": [...form.FamilyDetails.members],
      }
      
    
      // console.log(form["eduYear"])
      newData["ExStudent"] = form[fields[21].name]
      if(form["eduYear"]!==null) newData["eduYear"] = form[fields[22].name]
      else newData["eduYear"] = ""

      try{
        if(form["RazorpayPaymentID"] === "" || form["RazorpayPaymentID"] === null || form["RazorpayPaymentID"] === undefined){
          newData["RazorpayPaymentID"] = ""
        }
      }catch(e){
        newData["RazorpayPaymentID"] = ""
      }

      try{
        if(form["Company"] === "" || form["Company"] === null || form["Company"] === undefined){
          newData["Company"] = ""
        }
      }catch(e){
        newData["Company"] = ""
      }

      try{
        if(form["GrandFatherName"] === "" || form["GrandFatherName"] === null || form["GrandFatherName"] === undefined){
          newData["GrandFatherName"] = ""
        }
      }catch(e){
        newData["GrandFatherName"] = ""
      }

      try{
        if(form["InvoiceLink"] === "" || form["InvoiceLink"] === null || form["InvoiceLink"] === undefined){
          newData["InvoiceLink"] = ""
        }
      }catch(e){
        newData["InvoiceLink"] = ""
      }

      try{
        if(form["IDCARDLink"] === "" || form["IDCARDLink"] === null || form["IDCARDLink"] === undefined){
          newData["IDCARDLink"] = ""
        }
      }catch(e){
        newData["IDCARDLink"] = ""
      }

      try{
        if(form["Email"] === "" || form["Email"] === null || form["Email"] === undefined ){
          newData["Email"] = ""
        }
      }catch(e){
        newData["Email"] = ""
      }

      
      try{
        if(form.MDO.trim() === "") newData["MDO"] = {"value": "None", "other": ""}
      }catch(e){console.log()}


      if(form["FamilyID"]===null || form["FamilyID"]===undefined || form["FamilyID"]===""){
        newData["FamilyID"]="None"
      }else{
        if(form["FamilyID"].substring(0,4)==="FID_"){
          newData["FamilyID"] = form["FamilyID"]
        }else{
          newData["FamilyID"] = "None"
        }
      }


      if(type==='new') {
        newData = {
          ...newData,
          "Processed": true,
          "Verified": false,
          "Paid": false,
          "New": type==='new',
          "Self": true,
          "MDOBool": false,
          "UpdatedBy": {},
          "VerifiedBy": {},
          "MDO": {"value": "None", "other": ""}
        }
        try {
          const res = await protectedNewForm(newData).unwrap()
          // console.log(res)
          navigate('/')
          dispatch(resetForm())
        } catch(e) {
          // console.log(e)
          if(e["data"]["data"]==="already exists") alert("It seems like you have already filled the form once")
          else alert('Something went wrong')
          alert('Something went wrong')
        }
      }else{
        alert("404 not found")
      }
    }

    if(count===0){
      dispatch(setField(["mobile_otp error", false]))
      dispatch(setField(["email_otp error",false]))
      dispatch(setField(["mobile_otp", null]))
      dispatch(setField(["email_otp", null]))
      dispatch(setField(["verified_mobile", false]))
      dispatch(setField(["verified_email", false]))
      // dispatch(setField(["PhotoS3", null]))
      // dispatch(setField(["AadharS3", null]))
      dispatch(setField(["InvoiceLink", ""]))
      dispatch(setField(["IDCardLink", ""]))
      dispatch(setField(["MemberID", ""]))
      dispatch(setField(["ApplicationID", ""]))
      dispatch(setField(["FormFilledBy", {}]))
      dispatch(setField(["RazorpayPaymentID", ""]))
      dispatch(setField(["created_at", ""]))
    }
  }
  
  const submitForm = async () => {
    if(checked==="Personal Details") setChecked("Family Details")
    else if(checked === "Family Details") setChecked("Address Details")
    else if(checked==="Address Details") setChecked("Other")
    else if(checked==="Other") setChecked("Verification")
    else submit()
  }
  
  const go_back = () => {
    if(checked === "Family Details") setChecked("Personal Details")
    else if(checked==="Address Details") setChecked("Family Details")
    else if(checked==="Other") setChecked("Address Details")
    else if(checked==="Verification") setChecked("Other")
  }
  
  // console.log(sections[checked])
  return (
    <div className=''>
      <Navbar />
      <div className="px-4 sm:px-8 md:px-24 lg:px-48 relative py-4">
        <SideBar checked={checked} setChecked={setChecked} tabs={sections} />
        <Tabs checked={checked} setChecked={setChecked} tabs={sections} />
        
        {
          checked==="Personal Details" ? (<></>) : (
            <div className="flex w-full justify-center mb-2">
              <button
                type="button"
                className="h-12 my-2 rounded uppercase font-semibold text-purplegray-900 text-lg hover:shadow-purplegray-600/1.7 hover:shadow-xl p-2"
                onClick={() => go_back()}>
                  <h1>Go Back</h1>
              </button>
            </div>
          )
        }

        {
          checked === 'Family Details' ?
          <FamilyForm sections={sections[checked]} data={fields[32]} /> :
          checked === 'Other' ? 
          <OtherForm sections={sections[checked]} /> :
          sections[checked].map(field => <FieldSelector aadhar={aadhar} setAadhar={setAadhar} folder={folder} setFolder={setFolder} data={fields[field]} index={field} />)
        }

        <div className="flex w-full justify-center mb-2">
          <button
            type="button"
            className="w-full h-12 my-2 rounded bg-purplegray-900 uppercase font-semibold text-purplegray-100 text-lg hover:shadow-purplegray-600/1.7 hover:shadow-xl"
            onClick={() => submitForm()}
          >
            {
              checked==="Verification" ? (
                (loadingNew || loadingExisting || loadingUpdate) ? <VscLoading className='w-6 h-6 animate-spin mx-auto' /> : <h1>{(type!=='new' && type!=='existing') ? 'Update form' : 'Submit Form'}</h1>
              ) : (<h1>Next</h1>)
            }
          </button>
        </div>

      </div>
    </div>
  )
}

export default FormVariant