import React,{useState} from 'react'
import { useLocation, Link, useNavigate } from 'react-router-dom'
import {agentVerification, remove_duplicate } from '../../app/apis'
import {  toast } from 'react-toastify';
import LoadingBar from 'react-top-loading-bar'
import { default as ReactSelect } from "react-select";

const AgentBucketInfo = () => {
  const navigate = useNavigate()
  const location = useLocation();
  const bucketinfo = JSON.parse(localStorage.getItem("userinfo"));
  const [family,setFamily] = useState(bucketinfo.FamilyDetails.members)
  const [verify,setVerify] =useState([])
  const [optionSelected, setOptionSelected] = useState(null)
  const [classname,setClassname] = useState(`w-full p-2 border border-purple-500`)
  const [reject,setReject] =useState("")
  const [check,setCheck]=useState(false)
  const [progress, setProgress] = useState(0)

  let RemoveDuplicateAPI = async(data) => {
    setProgress(50)
    let response = await remove_duplicate(data);
    // console.log(response)
    toast.success('Removed from Duplicate', {
      position: "top-center",
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
    setProgress(100)
  }

  const agentVerify = async (data) => {
    setProgress(50)
    if(verify.length === 0 && data["reject"]===false){
      setCheck(true)
      setClassname(`w-full p-2 border border-red-600`)
      // console.log('object');
    }
    else{
      let b = false
      try{
        let aa = optionSelected[0]["value"]
      }catch(e){
        b = true
      }
      console.log(data)
      if(data["reject"] && IsOtherInReject() && data["reject reason"].trim().length===0){
        toast.error('Give reject reason', {
          position: "top-center",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });  
      }else if(data["reject"] && (optionSelected===null || b)){
        toast.error('Give reject reason', {
          position: "top-center",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });  
      }else{
        try{
          const at = JSON.parse(localStorage.getItem("data"))["token"]
          let rr = ""
          if(data["reject"]){
            for(let kk of optionSelected){
              if(kk["value"]!=="Other"){
                rr+=kk["value"]
                rr+=", "
              }else{
                rr+=reject
                rr+=", "
              }
            }
            data["reject reason"] = rr
          }

          const response = await agentVerification(data, at)
          if(response["data"]==="Application ID doesn't exist"){
            toast_msg("Application ID doesn't exist", true)
          }else{
            if(data["reject"]){
              toast_msg("Rejection message sent successfully", false)
              navigate("/agent-dashboard")
            }else{
              toast_msg("Record updated and sent for admin verification", false)
              navigate("/agent-dashboard")
            }
          }
        }catch(e){
          toast_msg("something went wrong", true)
        }
      }
    }
    setProgress(100)
  }

  const toast_msg = (msg, err) => {
    if(err===true){
      toast.error(msg, {
          position: "top-center",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
    }else{
      toast.success(msg, {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  let IsOtherInReject = () => {
    if(optionSelected!==null){
      for(let i of optionSelected){
        if(i["value"]==="Other") return true
      }
    }
    return false 
  }

  const RejectReasons = [
    { value: "Not found as a member in old database", label: "Not found as a member in old database" },
    { value: "Other", label: "Other" },
  ];

  return (
    <>
      <LoadingBar color='#f11946' progress={progress} />
      <div className="flex justify-center items-center flex-col w-auto my-10">
        <div className='flex justify-between bg-purple-600 text-white w-1/2 items-center m-1 p-3'>
          <div className='flex flex-row items-center justify-between w-full px-2'>
            <a href={bucketinfo.PhotoS3} target="_blank">
              <img src={bucketinfo.PhotoS3} alt="no image" className='h-[50px] w-[50px] rounded-full'
              />
            </a>
            <a href={bucketinfo.AadharS3} target="_blank"> <button className='ml-3 bg-white text-purple-900 px-4 py-1 rounded-lg'>
              Aadhaar/PAN
            </button>
            </a>
          </div>
        </div>
        <div className='flex justify-around bg-purple-600 text-white w-1/2 items-center m-1 p-2'>
          <span>First Name : {bucketinfo.FirstName}</span>
          <span>Middle Name : {bucketinfo.MiddleName}</span>
          <span>Last Name : {bucketinfo.LastName}</span>
        </div>

        <div className='flex justify-around bg-purple-100 text-purple w-1/2 items-center m-1 p-2'>
          <span>Gender : {bucketinfo.Gender}</span>
          <span>Email ID : {bucketinfo.Email}</span>
          <span>Occupation : {bucketinfo.Occupation}</span>
        </div>
        <div className='flex justify-around bg-purple-600 text-white w-1/2 items-center m-1 p-2'>
          {/* <span>Member ID : {bucketinfo.MemberID === null ? "NA" : bucketinfo.MemberID}</span> */}
          {/* <span>Application ID : {bucketinfo.ApplicationID}</span> */}
          <span>Aadhaar Number: {bucketinfo.AadharNumber}</span>
        </div>
        <div className='flex justify-around bg-purple-100 text-purple w-1/2 items-center m-1 p-2'>
          <span>Mobile : {bucketinfo.Mobile}</span>
          <span>DOB : {bucketinfo.DateOfBirth}</span>
          <span>Education Qualification : {bucketinfo.EducationQualification}</span>
          
        </div>
        <div className='flex justify-around bg-purple-600 text-white w-1/2 items-center m-1 p-2'>
          <span>Address: {bucketinfo.Address}</span>
        </div>
        <div className='flex justify-around bg-purple-100 text-purple w-1/2 items-center m-1 p-2'>
          <span>PinCode : {bucketinfo.Pincode}</span>
          <span>Native Village : {bucketinfo.NativeVillage}</span>
        </div>
        <div className='flex justify-around bg-purple-600 text-white w-1/2 items-center m-1 p-2'>
          <span>Blood Group: {bucketinfo.BloodGroups}</span>
          <span>Marital Status: {bucketinfo.MaritalStatus}</span>
        </div>
        <div className='flex justify-around bg-purple-100 text-purple w-1/2 items-center m-1 p-2'>
          <span>Area : {bucketinfo.Area}</span>
          <span>City : {bucketinfo.City}</span>
          <span>State: {bucketinfo.State}</span>
        </div>
        <div className='flex flex-col bg-purple-600 text-white w-1/2 items-center m-1 p-2'>
          <div className='flex justify-between items-center w-full'>
            <h1 className='font-semibold text-xl'>Family Details</h1>
            <h3 className='font-semibold text-mb'>
              Members: {bucketinfo.FamilyDetails.number ? bucketinfo.FamilyDetails.number : "NA"}
            </h3>
          </div>

          {family?.map((data,key)=>(
              <div className='flex justify-between items-center w-full'>
                <p>Name : {data.FamilyMemberName}</p>
                <p>Relation : {data.Relation}</p>
                <p>DOB : {data.RelativeDateOfBirth}</p>
                
              </div>
            ))}
          
        </div>
        <div className='w-1/2 flex justify-center items-center'>
          </div>    
        {bucketinfo.Verified ? null : <div className='flex w-1/2 flex-col justify-center items-center mt-3'>
          <div className='flex w-full'>
          <div className='w-5/6'>
            <ReactSelect
              options={RejectReasons}
              isMulti
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              onChange={(selected) => {setOptionSelected(selected)}}
              allowSelectAll={true}
              value={optionSelected}
            />
            {
              IsOtherInReject() ? (
                <input type="text" className={'w-full p-2 border border-purple-200'} placeholder='Please enter Reject Reason here' onChange={e => setReject(e.target.value)} />
              ) : (<div></div>)
            }
            </div>
            <div className='w-1/6'>
              <button className='p-2 bg-red-600 w-full text-white' 
                onClick={async ()=> await agentVerify({"reject": true, "reject reason": reject, "id": bucketinfo.recid })}>
                  Reject
              </button>
            </div>
          </div>
          <div className='flex w-full mt-2'>
            <div className='w-5/6'>
              <input type="text" className={classname} 
              onChange={e => setVerify(e.target.value)}
              placeholder='Enter Application ID' />
              {check ? <span className='text-red-600'>This field is required</span> : null}
            </div>
            <div className='w-1/6'>
              <button className='p-2 bg-green-600 w-full text-white' 
                onClick={
                     async ()=> {
                      if (window.confirm("Recheck the application ID: "+ verify)) {
                        await agentVerify({"app_id" : `${verify}`, "reject": false, "reject reason": "", "id": bucketinfo.recid })
                      } else {
                        
                      }
                    }
                  }>
                  Verify
              </button>
            </div>
          </div>
          {/* <button className='p-2 mt-10 bg-blue-600 w-full text-white' 
                onClick={async () => {console.log(bucketinfo.app_id); await RemoveDuplicateAPI({"app_id": bucketinfo.app_id})}}>
                  Remove as Duplicate
              </button> */}
        </div>}
      </div>

    </>
  )
}

export default AgentBucketInfo