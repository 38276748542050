import {cities} from './Cities'
import {states} from './State'
import {villages} from './villname'

export const sections = {
    "Personal Details" : [1, 2, 3, 4, 20, 5, 6, 8, 7, 18, 34, 19, 35, 10, 36, 37, 9, 11, 32],
    "Family Details" : [27, 28, 29, 33],
    "Address Details" : [15, 14, 13, 12, 16],
    "Other" : [21, 22, 30],
    "Verification" : [23, 24, 25, 31]
}

export const fields = {
    1: {
        label: 'First name',
        name: 'FirstName',
        type: 'text',
        question: 'Enter first name',
        regex: /^([A-Za-z])+$/,
        required: true,
        errorMsg: 'Invalid input',
        error: false,
    },
    2: {
        label: 'Middle name',
        name: 'MiddleName',
        type: 'text',
        question: 'Enter middle name',
        regex: /^([A-Za-z])+$/,
        required: true,
        errorMsg: 'Invalid input',
        error: false,
    },
    3: {
        label: 'Last name',
        name: 'LastName',
        type: 'text',
        question: 'Enter last name',
        regex: /^([A-Za-z])+$/,
        required: true,
        errorMsg: 'Invalid input',
        error: false,
    },
    4: {
        label: 'Grand Father name',
        name: 'GrandFatherName',
        type: 'text',
        question: "Enter name of Grandfather or Father in law",
        regex: /([A-Za-z])+$/,
        required: false,
        errorMsg: 'Invalid input',
        error: false,
    },
    5: {
        label: 'Gender',
        name: 'Gender',
        type: 'radio',
        question: 'Choose gender',
        regex: '',
        required: true,
        options: ['Male', 'Female', 'Other'],
        errorMsg: 'Invalid input',
        error: false,
    },
    6: {
        label: 'Date of Birth',
        name: 'DateOfBirth',
        type: 'date',
        question: 'Enter your date of birth',
        regex: '',
        required: true,
        errorMsg: 'Fill the field or ensure that age is above 18.',
        error: false,
    },
    7: {
        label: 'Marital Status',
        name: 'MaritalStatus',
        type: 'radio',
        question: 'Choose your martial status',
        regex: '',
        required: true,
        options: ['Single', 'Married', 'Widow', 'Divorced', 'Married to Non KVO'],
        errorMsg: 'Invalid input',
        error: false,
    },
    8: {
        label: 'Blood Group',
        name: 'BloodGroups',
        type: 'dropdown',
        options: ['None', 'NA', 'A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-'],
        question: 'Enter your blood group',
        regex: '',
        required: true,
        errorMsg: 'Invalid input',
        error: false,
    },
    9: {
        label: 'Educational Qualification',
        name: 'EducationQualification',
        type: 'text',
        question: 'Enter your educational qualification',
        regex: /([A-Za-z0-9])+/,
        required: true,
        errorMsg: 'Invalid input',
        error: false,
    },
    10: {
        label: 'Aadhar Number',
        name: 'AadharNumber',
        type: 'text',
        question: 'Enter the unique ID number of your government ID',
        regex: '',
        required: true,
        errorMsg: 'Invalid input',
        error: false,
    },
    11: {
        label: 'Occupation',
        name: 'Occupation',
        type: 'text',
        question: 'Enter your occupation',
        regex: /([A-Za-z])+$/,
        required: true,
        errorMsg: 'Invalid input',
        error: false,
    },
    12: {
        label: 'Residential Address',
        name: 'Address',
        type: 'text',
        question: 'Enter your residential address',
        regex: '',
        required: true,
        errorMsg: 'Invalid input',
        error: false,
    },
    13: {
        label: 'Area',
        name: 'Area',
        type: 'text',
        question: 'Enter your area',
        regex: /([A-Za-z])+/,
        required: true,
        errorMsg: 'Invalid input',
        error: false,
    },
    14: {
        label: 'City',
        name: 'City',
        type: 'dropdown',
        options: ['None'].concat(cities.split(',').sort()) ,
        question: 'Enter your city',
        regex: /([A-Za-z])+$/,
        required: true,
        errorMsg: 'Invalid input',
        error: false,
    },
    15: {
        label: 'State',
        name: 'State',
        type: 'dropdown',
        options: ['None'].concat(states.split(',').sort()) ,
        question: 'Enter your state',
        regex: /([A-Za-z])+$/,
        required: true,
        errorMsg: 'Invalid input',
        error: false,
    },
    16: {
        label: 'PIN Code',
        name: 'Pincode',
        type: 'text',
        question: 'Enter PIN code',
        regex: /^\d{6}$/,
        required: true,
        errorMsg: 'Invalid input',
        error: false,
    },
    17: {
        label: 'Phone Number',
        name: 'Phone',
        type: 'text',
        question: 'Enter phone number',
        regex: /^\d+$/,
        required: false,
        errorMsg: 'Invalid input',
        error: false,
    },
    18: {
        label: 'Mobile Number',
        name: 'Mobile',
        type: 'text',
        question: 'Enter your mobile number (Whatsapp Number)',
        regex: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
        required: true,
        errorMsg: 'Invalid input',
        error: false,
    },
    19: {
        label: 'Email ID',
        name: 'Email',
        type: 'text',
        question: 'Enter your email',
        regex: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
        required: false,
        errorMsg: 'Invalid input',
        error: false,
    },
    20: {
        label: 'Native Village',
        name: 'NativeVillage',
        type: 'dropdown',
        options: ["None"].concat(villages),
        question: 'Enter your village Name',
        regex: '',
        required: true,
        errorMsg: 'Invalid input',
        error: false,
    },
    21: {
        label: 'Ex-Student',
        name: 'ExStudent',
        type: 'radio',
        question: 'Were you Ex-Student of Institution?',
        regex: '',
        required: true,
        options: ['Yes', 'No'],
        errorMsg: 'Invalid input',
        error: false,
    },
    22: {
        label: 'Educational Year',
        name: 'eduYear',
        type: 'text',
        question: 'Enter the educational year',
        regex: /([A-Za-z0-9])+/,
        required: false,
        errorMsg: 'Invalid input',
        error: false,
    },
    23: {
        label: 'Photo',
        name: 'PhotoS3',
        type: 'photo',
        question: 'Upload your photo',
        regex: '',
        required: true,
        errorMsg: 'Invalid input',
        error: false,
    },
    24: {
        label: 'Aadhar Card',
        name: 'AadharS3',
        type: 'photo',
        question: 'Upload your government ID',
        regex: '',
        required: true,
        errorMsg: 'Invalid input',
        error: false,
    },
    25: {
        label: 'Mobile OTP',
        name: 'mobile_otp',
        type: 'verify',
        question: 'Enter Whatsapp Mobile OTP',
        regex: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
        required: false,
        errorMsg: 'Verification Failed',
        error: false,
    },
    26: {
        label: 'Email OTP',
        name: 'email_otp',
        type: 'verify',
        question: 'Enter email OTP (check spam)',
        regex: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
        required: false,
        errorMsg: 'Verification Failed',
        error: false,
    },
    27: {
        label: 'Family member name',
        name: 'FamilyMemberName',
        type: 'text',
        question: 'Enter family member name',
        regex: /([A-Za-z])+$/,
        required: false,
        errorMsg: 'Invalid input',
        error: false,
    },
    28: {
        label: 'Relation',
        name: 'Relation',
        type: 'dropdown',
        question: 'Enter your relation with them',
        options: ['None', 'Father', 'Wife', 'Mother', 'Son', 'Daughter', 'Grandfather', 'GrandMother', 'Grandson', 'Grnaddaughter'],
        regex: /([A-Za-z])+$/,
        required: false,
        errorMsg: 'Invalid input',
        error: false,
    },
    29: {
        label: 'Relative Date of Birth',
        name: 'RelativeDateOfBirth',
        type: 'date',
        question: 'Enter their date of birth',
        regex: '',
        required: false,
        errorMsg: 'Invalid input',
        error: false,
    },
    30: {
        label: 'References',
        max: 2,
        name: 'References',
        type: 'group',
        fields: ['Name', 'Number', 'Village Name'],
        required: true,
        errorMsg: 'Put 2 references',
        error: false,
    },
    31: {
        label: 'TnC',
        name: 'TnC',
        type: 'radio',
        question: 'I declare that I am from Kutchhi Visa Oswal Jain community and whatever details submitted above is true and correct. I expressely understand that my membership is liable to be cancelled if any of the information given above is found to be false. I have read by-laws and constitution of the organisation and I abide to follow Rules and regulations. I want to become life member of Shree Hirji Bhojraj & Sons Kuchi Visa Oswal Jain Chhatralay. I will pay membership fees of Rs. 500+ gst after approval of my membership by managing committee as per by-laws and constitution of the organisation. I also know & acknowledge that the rights of sanstha & the sanstha has right to change rules & regulations as deemed fit by them. Please accept my life membership application and oblige',
        regex: '',
        required: false,
        options: ['I agree with the declaration'],
        errorMsg: 'Invalid input',
        error: false,
    },
    32: {
        label: 'Company',
        name: 'Company',
        type: 'text',
        question: 'Enter name of company/organization if you are filling as a representative',
        regex: /([A-Za-z])+$/,
        required: false,
        errorMsg: 'Invalid input',
        error: false,
    },
    33: {
        label: 'FamilyID',
        name: 'FamilyID',
        type: 'text',
        question: 'Enter your Family ID here. The Family ID must have been mailed or whatsapped to a direct family member who has already filled the formed. If no one in your family has filled the form, then we will generate a new family id for you. You can, in that case leave the field blank.',
        regex: /([A-Za-z0-9])+$/,
        required: false,
        errorMsg: 'Invalid input',
        error: false,
    },
    34: {
        label: 'Mobile Number 2',
        name: 'Mobile2',
        type: 'text',
        question: 'Enter another mobile number if whatsapp number and calling number are different.',
        regex: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
        required: false,
        errorMsg: 'Invalid input',
        error: false,
    },
    35: {
        label: 'GovernmentID',
        name: 'GovernmentID',
        type: 'dropdown',
        question: 'Which Government ID will you be submitting?',
        options: ['None', 'Aadhar card', 'Passport', 'Drivers license', 'Voters ID'],
        regex: /([A-Za-z])+$/,
        required: false,
        errorMsg: 'Invalid input',
        error: false,
    },
    36: {
        label: 'MaidenName',
        name: 'MaidenName',
        type: 'text',
        question: 'Enter maiden name (if applicable)',
        regex: '',
        required: false,
        errorMsg: 'Invalid input',
        error: false,
    },
    37: {
        label: 'MaidenVillage',
        name: 'MaidenVillage',
        type: 'dropdown',
        options: ["None", "Other"].concat(villages),
        question: 'Enter the name of your maiden village (if applicable)',
        regex: /([A-Za-z])+$/,
        required: false,
        errorMsg: 'Invalid input',
        error: false,
    },
}

export const names = () => {
    const list = {}
    // console.log(Object.keys(fields))
    for(let i of Object.keys(fields)) list[fields[i].name] = null
    list['FamilyDetails'] = {
        number: 0,
        members: []
    }
    list['References'] = []
    list['verified_mobile'] = false
    list['verified_email'] = false
    list['MDO'] = ''
    list["Processed"] = false
    list["Verified"] = false
    list["Paid"] = false
    list["New"] = true
    list["Self"] = true
    list["MDOBool"] = false
    list["UpdatedBy"] = ''
    list["VerifiedBy"] = ''
    return list
}