import React, { useState } from 'react'
import { useEffect } from 'react'
import { getreportinfo, create_report } from '../../app/apis'
import Loader from '../../components/Loader'

const Reports = () => {

    const [reportData, setReportData] = useState([])
    const [load, setLoad] = useState(false);

    let getreportinfoapicall = async () => {
        let res = await getreportinfo()
        setReportData(res.data)
    }


    useEffect(() => {
        getreportinfoapicall();
      }, ['initial'])

    let create_report_api_call = async (name, count) => {
        if(count===0) alert("Cannot create report for 0 count!")
        else{
            setLoad(true)
            let res = await create_report({"report_choice": name})
            let filename = name + ".xlsx";
            res.blob().then(blob => download(blob, filename));
            setLoad(false)
        }
    }

    let download = (blob, filename) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    }

    if(load){
        return(
            <>
                <div className=' w-full flex justify-between'>
                    <h1 className='font-semibold text-lg sm:text-xl py-2 text-center md:text-4xl heading text-purple-600 mb-2'>
                        Report Information
                    </h1>
                </div>
                <h3>This May Take a while. Please Wait! Creating your report!</h3>
                <Loader/>
            </>
        )
    }

    return (
        <>
            <div className=' w-full flex justify-between'>
                <h1 className='font-semibold text-lg sm:text-xl py-2 text-center md:text-4xl heading text-purple-600 mb-2'>
                    Report Information
                </h1>
            </div>
            <div class="flex flex-col">
                <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                        <div class="overflow-hidden">
                            <table class="min-w-full">
                                <thead class="border-b">
                                    <tr className='bg-purple-800 uppercase'>
                                        <th scope="col" class="text-sm font-medium  text-gray-100 px-6 py-4 text-left">
                                            #
                                        </th>
                                        <th scope="col" class="text-sm font-medium text-gray-100 px-6 py-4 text-left">
                                            Type
                                        </th>
                                        <th scope="col" class="text-sm font-medium text-gray-100 px-6 py-4 text-left">
                                            count
                                        </th>
                                        <th scope="col" class="text-sm font-medium text-gray-100 px-6 py-4 text-left">
                                            Download
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {reportData.map((data, key) => (
                                        <tr key={key} class="border-b">
                                            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                {data.id}
                                            </td>
                                            <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                {data.name}
                                            </td>
                                            <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                {data.count}
                                            </td>
                                            <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                <button onClick={() => create_report_api_call(data.name, data.count)} className='bg-purple-600 px-7 py-2 text-white rounded-lg w-1/3'>Download</button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Reports